import http from "./http";
export default (accountId) => ({
  async index(filters) {
    return http
      .index("accounts", filters)
      .then((json) => ({ accounts: json }))
      .catch(async (e) => ({ accounts: null, error: await e }));
  },
  async get() {
    return http
      .get("accounts", accountId)
      .then((json) => ({ account: json }))
      .catch(async (e) => ({ account: null, error: await e }));
  },
  async create(data) {
    return http
      .post("accounts", data)
      .then((json) => ({ account: json }))
      .catch(async (e) => ({ account: null, error: await e }));
  },
  async update(data) {
    return http
      .put("accounts", accountId, data)
      .then((json) => ({ account: json }))
      .catch(async (e) => ({ account: null, error: await e }));
  },
  async delete() {
    return http
      .delete("accounts", accountId)
      .then((json) => ({ account: json }))
      .catch(async (e) => ({ account: null, error: await e }));
  },
});
