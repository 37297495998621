<template>
  <el-dialog
    lock-scroll
    v-model="shouldShow"
    v-loading="isLoading"
    append-to-body
    width="95%"
    :title="`Entrega do pedido ${order?.code || 'S/N'}`"
  >
    <el-descriptions
      class="margin-top"
      :column="isSmallScreen ? 1 : 2"
      size="small"
      border
    >
      <el-descriptions-item>
        <template #label> Cliente </template>
        {{ order?.client?.name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Data: </template>
        {{ dateFormatter.format(new Date(order_.deliver_at || Date.now())) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Faturamento </template>
        {{ order.payment_method }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Prazo:</template>
        {{ PaymentTerms[order_.payment_days] || "A vista" }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Romaneio </template>
        {{ order.invoice_id }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Veículo:</template>
        {{ order_?.car?.name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Motorista:</template>
        {{ order_?.driver?.employee?.name || "Sem motorista" }}
      </el-descriptions-item>
    </el-descriptions>

    <el-row type="flex" justify="start" class="mt-1">
      <h4 class="mb-0">Produtos</h4>
    </el-row>

    <el-table
      :data="OrderProducts"
      empty-text="Nenhum produto foi cadastrado na ordem"
      max-height="400px"
      :cell-style="{ 'padding-left': '4px', 'padding-right': '4px' }"
    >
      <el-table-column
        label="produto"
        prop="product.name"
        :formatter="(r) => printProductMountedName(r?.mounted)"
      ></el-table-column>
      <el-table-column
        label="peso"
        prop="weight"
        :formatter="(r) => weightFormatter.format(Number(r.weight) || 0)"
      >
      </el-table-column>
    </el-table>

    <template #footer>
      <el-row justify="center" type="flex">
        <el-button
          @click="openOrderReceivePaymentModal()"
          type="primary"
          size="large"
          plain
          class="button"
          >Entregar</el-button
        >
      </el-row>
    </template>

    <order-receive-payment-modal
      :showModal="showOrderReceivePaymentModal"
      :order="order"
      @close-modal="showOrderReceivePaymentModal = false"
      @payments-updated="
        updateOrder({
          delivered_at: new Date(),
          status: 'completed',
          latitude: coordinates?.latitude,
          longitude: coordinates?.longitude,
        }) |
          $emit('should-update') |
          $emit('close-modal')
      "
    ></order-receive-payment-modal>
  </el-dialog>
</template>
<script>
import { ElNotification } from "element-plus";
import OrderService from "../../services/orders";
import OrderReceivePaymentModal from "./OrderReceivePaymentModal.vue";
import {
  currencyFormatter,
  dateFormatter,
  weightFormatter,
} from "../../utils/formatters";

export default {
  props: ["order", "showModal"],
  components: { OrderReceivePaymentModal },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      order_: {},
      showOrderReceivePaymentModal: false,
      obs: "",
      coordinates: null,
      isLoading: false,
      dateFormatter,
      weightFormatter,
      currencyFormatter,
    };
  },
  watch: {
    order(v) {
      if (v.client) {
        this.clients = [v.client];
      }
    },
    showModal(v) {
      if (v && this.order) {
        this.isLoading = true;
        this.getCurrentPosition();
        this.findOrder(this.order.uid);
      }
    },
  },
  computed: {
    isSmallScreen() {
      return window.innerWidth <= 768;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    OrderProducts() {
      return this.order_?.products || [];
    },
    AllPaymentsAreChecked() {
      return this.order_.payments?.every((p) => p.is_checked);
    },
    can() {
      return this.$store.state.auth.can;
    },
    OrderIsInProduction() {
      return ["created", "accepted", "production", "delayed"].includes(
        this.order_.status
      );
    },
    PaymentDays() {
      return [
        {
          label: "A vista",
          value: "0",
        },
        {
          label: "3 dias",
          value: "3",
        },
        {
          label: "4 dias",
          value: "4",
        },
        {
          label: "5 dias",
          value: "5",
        },
        {
          label: "7 dias",
          value: "7",
        },
        {
          label: "10 dias",
          value: "10",
        },
        {
          label: "14 dias",
          value: "14",
        },
        {
          label: "15 dias",
          value: "15",
        },
        {
          label: "21 dias",
          value: 21,
        },
        {
          label: "30 dias",
          value: 30,
        },
      ];
    },
    PaymentTerms() {
      return this.PaymentDays.reduce((t, d) => {
        t[d.value] = d.label;
        return t;
      }, {});
    },
  },
  methods: {
    openOrderReceivePaymentModal() {
      this.showOrderReceivePaymentModal = true;
    },
    canAny() {
      for (let a in arguments) if (this.can(arguments[a])) return true;

      return false;
    },
    async getCurrentPosition() {
      if ("geolocation" in navigator) {
        const self = this;
        navigator.geolocation.getCurrentPosition((position) => {
          self.coordinates = position.coords;
        });
      } else this.$message.error("Seu navegador não suporta geolocalização");
    },
    printProductMountedName(mounted) {
      return `${mounted?.product?.name} ${mounted?.cutting ?? ""} ${
        mounted?.composition ?? ""
      } ${mounted?.storage ?? ""}`;
    },
    async updateOrder(orderData) {
      let success = false;
      if (orderData) {
        const { order, error } = await OrderService(this.order_.uid).update(
          orderData
        );

        if (error)
          ElNotification.error({
            title: "Ocorreu um erro ao atualizar o vínculo",
            message: error.message,
            position: "bottom-right",
          });
        else {
          this.findOrder(this.order_.uid);
          this.$emit("should-update");
          success = true;
          ElNotification.success({
            title: order.message,
            position: "bottom-right",
          });
        }
      }

      return success;
    },
    async findOrder(uid) {
      const { order } = await OrderService(uid).get();

      if (order) this.order_ = order;

      this.isLoading = false;
    },
  },
  name: "DeliverOrderModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.has-error input {
  border-color: red !important;
}
.text-grey {
  color: grey;
}
.mb-0 {
  margin-bottom: 0px !important;
}
</style>
