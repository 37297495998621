import http from "./http";
export default (invoiceId) => ({
  async index(filters) {
    return http
      .index("invoices", filters)
      .then((json) => ({ invoices: json }))
      .catch(async (e) => ({ invoices: null, error: await e }));
  },
  async get() {
    return http
      .get("invoices", invoiceId)
      .then((json) => ({ invoice: json }))
      .catch(async (e) => ({ invoice: null, error: await e }));
  },
  async create(data) {
    return http
      .post("invoices", data)
      .then((json) => ({ invoice: json }))
      .catch(async (e) => ({ invoice: null, error: await e }));
  },
  async update(data) {
    return http
      .put("invoices", invoiceId, data)
      .then((json) => ({ invoice: json }))
      .catch(async (e) => ({ invoice: null, error: await e }));
  },
  async delete() {
    return http
      .delete("invoices", invoiceId)
      .then((json) => ({ invoice: json }))
      .catch(async (e) => ({ invoice: null, error: await e }));
  },
});
