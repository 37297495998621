<template>
  <div>
    <el-card v-loading="isLoading" shadow="always">
      <template #header>
        <el-row type="flex" justify="between" align="center">
          <el-col :md="12" :xs="24">
            <el-row type="flex" justify="start">
              <h4>Entrega de Pedidos</h4>
            </el-row>
          </el-col>
          <el-col :md="12" :xs="24">
            <el-row type="flex" justify="end">
              <el-dropdown @command="(c) => filterOrders(c)" size="medium" trigger="click">
                <el-button type="primary" size="medium">
                  Exibir por: {{ displayPage || ""
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="Praça">
                      <li :class="{
                          'el-dropdown-menu__item': true,
                          'no-padding': true,
                          active: displayPage === 'Praça',
                        }">
                        Praça
                      </li>
                    </el-dropdown-item>
                    <el-dropdown-item command="Licitação">
                      <li :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Licitação',
                      }">
                        Licitação
                      </li>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-row>
          </el-col>
        </el-row>

      </template>

      <el-empty v-if="!Object.keys(GroupedOrders)?.length" description="Nenhum pedido nesta lista"></el-empty>

      <div v-for="(orders, route) in GroupedOrders" v-else :key="route">
        <h2 class="el-table__subheader_name">{{ route }}</h2>

        <el-table :cell-style="() => 'text-align:center;'" :data="orders" style="width: 100%; z-index: 0"
          :span-method="spanDateRow" :row-style="(r) =>
            'cursor:pointer;' +
            `background-color: ${r.row.status === 'delivered' ? '#dcedc8' : '#fff'
            };`
            " @row-click="(r) => openOrderModal(r)">
          <el-table-column label="#" prop="code" width="100">
            <template #default="{ row: order }">
              <span v-if="order.type === 'date'" class="el-table__subheader_date">
                {{ order.date }}</span>
              <span v-else>
                {{ order.code }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="romaneio" prop="invoice_id" width="100" v-if="!isSmallScreen"></el-table-column>
          <el-table-column prop="seller.name" label="vendedor" v-if="!isSmallScreen">
          </el-table-column>
          <el-table-column prop="client.legal.company_name" label="razão social"></el-table-column>
          <el-table-column prop="client.name" label="cliente">
            <template #default="{ row: order }">
              {{ order?.client?.legal?.social_name || order?.client?.name }}
            </template>
          </el-table-column>
          <el-table-column prop="car.name" label="veículo" v-if="!isSmallScreen">
          </el-table-column>
          <el-table-column prop="client.route.name" label="rota" v-if="!isSmallScreen">
          </el-table-column>
          <el-table-column label="data pedido" width="100" :formatter="(r) =>
            r.created_at &&
            dateFormatter.format(new Date(r.created_at || Date.now()))
            " v-if="!isSmallScreen">
          </el-table-column>
          <el-table-column label="prazo de entrega" width="120" :formatter="(r) =>
            r.deliver_at &&
            dateFormatter.format(new Date(r.deliver_at) || Date.now())
            " v-if="!isSmallScreen">
          </el-table-column>
          <el-table-column label="entregar">
            <template #default="{ row: order }">
              <el-button onlyIcon type="primary" :disabled="order.status !== 'delivery'"
                v-on:click.stop="openDeliverOrderModal(order)" v-if="order.type !== 'date'">
                Entregar pedido</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <order-modal :order="order" :showModal="showOrderModal" @should-update="fetchOrders"
        @close-modal="showOrderModal = false"></order-modal>
      <deliver-order-modal :order="order" :showModal="showDeliverOrderModal" @should-update="fetchOrders"
        @close-modal="showDeliverOrderModal = false"></deliver-order-modal>
    </el-card>
  </div>
</template>

<script>
//import { ElNotification } from "element-plus";
import OrderModal from "./modals/OrderModal.vue";
import DeliverOrderModal from "./modals/DeliverOrderModal.vue";
import OrderService from "../services/orders";
import { hasOnlyDigits } from "../utils/functions";
import { dateFormatter, currencyFormatter } from "../utils/formatters";
export default {
  name: "OrdersDeliverPage",
  components: { OrderModal, DeliverOrderModal },
  data: () => ({
    hasError: false,
    order: {},
    showOrderModal: false,
    showDeliverOrderModal: false,
    isLoading: true,
    orders: null,
    currencyFormatter,
    dateFormatter,
    displayPage: 'Praça',
    fetchRepeater: null,
  }),
  mounted() {
    this.fetchOrders();
    this.fetchRepeater = setInterval(() => this.fetchOrders(false), 5000);
  },
  unmounted() {
    clearInterval(this.fetchRepeater);
  },
  watch: {
    SearchName() {
      this.fetchOrders();
    },
  },
  computed: {
    Orders() {
      return this.orders || [];
    },
    GroupedOrders() {
      return this.groupByDeliverDate(this.groupByDriversName());
    },
    Can() {
      return this.$store.state.auth.can;
    },
    isSmallScreen() {
      return window.innerWidth <= 768;
    },
    SearchName() {
      return this.$store.state.searchNameQuery;
    },
  },
  methods: {
    spanDateRow({ row: order, columnIndex }) {
      if (order.type === "date" && columnIndex === 0) return [1, 4];
    },
    groupByDeliverDate(groupedOrders) {
      const groupedByDeliver = {};

      if (groupedOrders) {
        Object.entries(groupedOrders).forEach(([driver, orders]) => {
          groupedByDeliver[driver] = orders;

          if (Array.isArray(orders)) {
            const dates = orders.reduce((t, o) => {
              t[this.formatDate(o.deliver_at)] = true;
              return t;
            }, {});

            Object.keys(dates).forEach((date) => {
              const dateIndex = orders.findIndex(
                (o) => this.formatDate(o.deliver_at) === date
              );
              if (dateIndex >= 0) {
                orders.splice(dateIndex, 0, {
                  type: "date",
                  date,
                });
              }
            });
          }
        });
      }

      return groupedByDeliver;
    },
    formatDate(c) {
      if (new Date(`${c}`)?.getTime())
        return this.dateFormatter.format(new Date(c));
      else return "";
    },
    canAny() {
      for (let a in arguments) if (this.Can(arguments[a])) return true;

      return false;
    },
    openOrderModal(order) {
      this.order = order;
      this.showOrderModal = true;
    },
    groupByDriversName() {
      return (
        this.orders?.reduce((t, o) => {
          const driver = o.driver?.employee?.name || "SEM MOTORISTA";
          if (t[driver]) t[driver].push(o);
          else t[driver] = [o];

          return t;
        }, {}) || {}
      );
    },
    openDeliverOrderModal(order) {
      this.order = order;
      this.showDeliverOrderModal = true;
    },
    async fetchOrders(showLoading = true) {
      if (showLoading) this.isLoading = true;
      const status = this.canAny("UpdateOrder")
        ? "delivery,delivered"
        : "delivery";
      const { orders } = await OrderService().index({
        status,
        searchName: (!hasOnlyDigits(this.SearchName) && this.SearchName) || "",
        searchCpfCnpj:
          (hasOnlyDigits(this.SearchName) && this.SearchName) || "",
      });

      if (orders) this.orders = orders;

      this.isLoading = false;
    },
  },
};
</script>
