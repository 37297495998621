<template>
  <el-dialog title="Nota Fiscal e Boleto" v-model="shouldShow">
    <h4>Selecione uma empresa para gerar a nota fiscal</h4>
    <h5 class="subtitle">
      Deixe em branco para não gerar nota fiscal para o pedido
    </h5>
    <el-select
      v-model="firm_invoice_id"
      size="medium"
      clearable
      :disabled="!Number(order.invoice_config)"
    >
      <template #prefix> Empresa: </template>
      <el-option
        v-for="firm in Firms"
        :key="firm.uid"
        :disabled="!clientHasFirm(firm)"
        :label="firm.name"
        :value="firm.uid"
      >
      </el-option>
    </el-select>

    <h4>Selecione uma carteira para gerar um boleto</h4>
    <h5 class="subtitle">Deixe em branco para não gerar um boleto</h5>
    <el-select
      v-model="bank_billet_id"
      size="medium"
      clearable
      :disabled="order.payment_method !== 'billet'"
    >
      <template #prefix> Carteira: </template>
      <el-option
        v-for="item in Accounts"
        :key="item"
        :label="item.account_label"
        :disabled="!clientHasFirmBankAccount(item)"
        :value="item.uid"
      >
      </el-option>
    </el-select>
    <div v-if="!!OrderHasProblems?.length">
      <h4 class="danger" v-for="problem in OrderHasProblems" :key="problem">
        {{ problem }}
      </h4>
    </div>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          class="button"
          @click="save"
          :loading="isLoadingSave"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import FirmService from "../../services/firms";
import AccountService from "../../services/accounts";
import OrderService from "../../services/orders";
import BilletService from "../../services/billets";
import InvoiceService from "../../services/invoices";
import { messageOnCenter, notifyError } from "../../utils/notifiers";
export default {
  props: ["order", "showModal"],
  components: {},
  emits: ["close-modal", "should-update"],
  data() {
    return {
      order_: { ...(this.order || {}) },
      isLoadingSave: false,
      firms: null,
      accounts: null,
      firm_invoice_id: null,
      bank_billet_id: null,
    };
  },
  watch: {
    order(v) {
      this.order_ = { ...(v || {}) };
    },
    async showModal(v) {
      this.firm_invoice_id = null;
      this.bank_billet_id = null;
      if (v) {
        await this.fetchPartnerFirmAccounts();
        await this.fetchFirms();

        if (!this.invoice_id && !this.bank_billet_id) this.save();
      }
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    Firms() {
      return this.firms || [];
    },
    Accounts() {
      return this.accounts || [];
    },
    OrderHasProblems() {
      const errors = [];
      if (this.clientHasIncorrectAddress())
        errors.push(this.clientHasIncorrectAddress());
      if (this.clientHasIncorrectInfo())
        errors.push(this.clientHasIncorrectInfo());
      return errors;
    },
  },
  methods: {
    clientHasIncorrectAddress() {
      if (this.order.client?.addr?.postal_code_formatted?.length < 8)
        return "O CEP do cliente está incompleto";
    },
    clientHasIncorrectInfo() {
      if (this.order.client.legal && !this.order.client.legal.state_registry)
        return "O cliente não possui inscrição estadual no seu cadastro";
    },
    selectFirmAndAccount() {
      const firm = this.Firms.find((f) => this.clientHasFirm(f));
      const account = this.Accounts.find((a) =>
        this.clientHasFirmBankAccount(a)
      );

      if (firm && !!Number(this.order_.invoice_config))
        this.firm_invoice_id = firm.uid;
      if (account && this.order_.payment_method === "billet")
        this.bank_billet_id = account.uid;
    },
    async fetchFirms() {
      const { firms } = await FirmService().index();

      if (firms) this.firms = firms;

      this.selectFirmAndAccount();
    },
    async fetchPartnerFirmAccounts() {
      const { accounts } = await AccountService().index();

      if (accounts) this.accounts = accounts;

      this.selectFirmAndAccount();
    },
    clientHasFirmBankAccount(bank_account) {
      return !!this.order?.client?.bank_accounts?.find(
        (account) => account.uid === bank_account.uid
      );
    },
    clientHasFirm(firm) {
      return !!(this.order?.client?.firm?.uid === firm?.uid);
    },
    async createBilletFromOrder() {
      if (this.thereIsNotPayments()) {
        notifyError(
          "Não é possível gerar os boletos desse pedido",
          "Nenhum pagamento do pedido foi cadastrado"
        );

        throw "Erro";
      }

      const queries = this.order.payments.map((p) =>
        BilletService().create({
          firm_bank_account_id: this.bank_billet_id,
          order_payments_id: p.uid,
          partner_id: this.order.client.uid,
          expires_at: new Date(p.expires_at),
          amount: Number(p.amount),
          order_id: this.order.uid,
        })
      );

      const [{ error }] = await Promise.all(queries);

      if (error) {
        if (error) {
          messageOnCenter(undefined, error.message, "error");
          throw error.message;
        }
      }
    },
    thereIsNotPayments() {
      return !this.order.payments?.length;
    },
    async createInvoiceFromOrder() {
      const { error } = await InvoiceService().create({
        partner_id: this.order.client.uid,
        order_id: this.order.uid,
        firm_id: this.firm_invoice_id,
      });

      if (error) {
        messageOnCenter(undefined, error.message, "error");
        throw error.message;
      }
    },
    getPaymentTermsInMiliseconds() {
      const aDayInMiliseconds = 864e5;

      return this.order?.payment_days * aDayInMiliseconds || 0;
    },
    async updateOrderStatus() {
      await OrderService(this.order.uid).update({
        status: "delivery",
        checked_at: new Date(),
      });
    },
    async save() {
      this.isLoadingSave = true;

      try {
        if (this.firm_invoice_id) await this.createInvoiceFromOrder();

        if (this.bank_billet_id) await this.createBilletFromOrder();

        await this.updateOrderStatus();

        this.navigateToPrintOrder();
      } finally {
        this.isLoadingSave = false;
      }
    },
    navigateToPrintOrder() {
      this.$router.push(`/pedidos/${this.order.uid}/imprimir`);
    },
  },
  name: "PrepareDeliverOrderModal",
};
</script>
<style scoped>
.subtitle {
  margin-top: -10px;
  color: grey;
}
</style>
