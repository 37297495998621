<template>
  <div>
    <el-descriptions :column="3" size="large" border>
      <el-descriptions-item>
        <template #label>Total de Itens:</template>
        {{ order?.products?.length }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Valor Total:</template>
        {{ currencyFormatter.format(order?.total_amount || 0) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Data da entrega:</template>
        {{
          order?.delivered_at
            ? dateFormatterLong.format(new Date(order?.delivered_at))
            : "Não entregue"
        }}
      </el-descriptions-item>
    </el-descriptions>
    <div id="mapDiv" style="min-height: 450px" v-if="hasCoords()"></div>
    <el-empty
      v-else
      description="Este pedido não possui localização da entrega"
    ></el-empty>
  </div>
</template>
<script>
var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");
import {
  currencyFormatter,
  numberFormatter,
  dateFormatterLong,
} from "../../../utils/formatters";

export default {
  name: "DeliveryInfo",
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoiamFjb25zbW9yYWlzIiwiYSI6ImNrNW1uMGNrMjExbGQzcG83M3EwNDJlcWYifQ.astqW0QEC039TVEbzfo-lA",
      mapBox: null,
      currencyFormatter,
      numberFormatter,
      dateFormatterLong,
    };
  },
  props: ["order"],
  mounted() {
    this.initializeMap();
  },
  beforeUnmount() {
    this.mapBox?.remove();
  },
  methods: {
    hasCoords() {
      return this.order?.latitude && this.order?.longitude;
    },
    initializeMap() {
      const self = this;

      const { latitude, longitude } = this.order;

      if (this.hasCoords()) {
        setTimeout(() => {
          mapboxgl.accessToken = self.accessToken;
          self.mapBox = new mapboxgl.Map({
            container: "mapDiv",
            style: "mapbox://styles/jaconsmorais/ckw2qm6gk11bg15msgqn9ynji",
            center: [Number(longitude), Number(latitude)],
            zoom: 15,
          });

          new mapboxgl.Marker({
            color: "#0d84ff",
            draggable: false,
          })
            .setLngLat([Number(longitude), Number(latitude)])
            .addTo(self.mapBox);
        }, 500);
      }
    },
  },
};
</script>
