<template>
  <div>
    <el-dialog
      lock-scroll
      v-model="shouldShow"
      v-loading="isLoading"
      width="100%"
      :title="`Pedido ${order?.code || 'S/N'}`"
    >
      <el-row type="flex" justify="end">
        <el-button
          type="primary"
          @click="createOrderInvoice()"
          :loading="isCreatingInvoice"
          :disabled="!!order_.invoice?.nfe"
          v-if="canAny('SuperOrder', 'CreateInvoice')"
          style="margin-right: 16px"
          >Emitir NF</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="navigateToPrintOrder"
          style="margin-right: 16px"
          >Imprimir pedido</el-button
        >
      </el-row>

      <el-tabs type="border">
        <el-tab-pane label="Informações">
          <OrderModalMainInfo
            :order="order_"
            :client="OrderClient"
            @should-update="findOrder(order_?.uid)"
          ></OrderModalMainInfo>
        </el-tab-pane>
        <el-tab-pane label="Entrega">
          <DeliveryInfo :order="order_" :key="order_?.uid"></DeliveryInfo>
        </el-tab-pane>
        <el-tab-pane label="Pagamento">
          <PaymentInfo
            :order="order_"
            @should-update="findOrder(order.uid) | $emit('should-update')"
          ></PaymentInfo>
        </el-tab-pane>
      </el-tabs>

      <template #footer>
        <el-row style="width: 100%" type="flex" justify="space-between">
          <el-row type="flex" justify="start"
            ><el-popconfirm
              v-if="canShowElement('CancelOrderButton')"
              @confirm="
                updateOrder({ status: 'canceled' }) | $emit('close-modal')
              "
              title="
              Tem certeza?
            "
              ><template #reference>
                <el-button type="warning" plain class="button"
                  >Cancelar pedido</el-button
                >
              </template></el-popconfirm
            >
            <el-popconfirm
              @confirm="
                updateOrder({ status: 'production' }) | $emit('close-modal')
              "
              v-if="canShowElement('SendToProductionButton')"
              title="
              Tem certeza?
            "
              ><template #reference>
                <el-button type="warning" plain class="button"
                  >Devolver para fábrica</el-button
                >
              </template></el-popconfirm
            >
            <el-popconfirm
              @confirm="updateOrder({ status: getPreviousStatus() })"
              v-if="canShowElement('BackOrderButton')"
              title="
              Tem certeza?
            "
              ><template #reference>
                <el-button type="warning" plain class="button"
                  >Devolver pedido</el-button
                >
              </template></el-popconfirm
            >
            <el-popconfirm
              @confirm="deleteOrder()"
              v-if="canShowElement('RemoveOrderButton')"
              title="
              Tem certeza?
            "
              ><template #reference>
                <el-button type="danger" plain class="button"
                  >Excluir pedido</el-button
                >
              </template></el-popconfirm
            ></el-row
          >
          <el-row type="flex" justify="end">
            <el-popconfirm
              @confirm="finalizeProduction"
              v-if="
                canShowElement('FinalizeProductionButton') &&
                OrderIsInProduction
              "
              title="
              Tem certeza?
            "
              ><template #reference>
                <el-button type="primary" plain class="button"
                  >Finalizar produção</el-button
                >
              </template></el-popconfirm
            >
            <el-popconfirm
              @confirm="recriateBillets"
              v-if="AllowRecriateBillets"
              title="Tem certeza?"
              ><template #reference>
                <el-button type="primary" plain class="button"
                  >Recriar boletos</el-button
                >
              </template></el-popconfirm
            >
            <el-popover
              placement="left-start"
              title="Problemas no pedido"
              :width="350"
              v-if="canShowElement('AcceptOrderButton') && !OrderIsInProduction"
              trigger="hover"
              :content="order_.errors || ''"
            >
              <template #reference>
                <el-button
                  type="primary"
                  plain
                  class="button"
                  @click="
                    updateOrder({
                      uid: order_?.uid,
                      status: order_?.next_status || 'created',
                    }) | $emit('close-modal')
                  "
                  >Aceitar pedido (Enviar para
                  {{ order_?.next_status_formatted || "produção" }})</el-button
                >
              </template>
            </el-popover>
            <el-popconfirm
              @confirm="deliveryOrder()"
              v-if="canShowElement('DeliverOrderButton')"
              title="
              Tem certeza?
            "
              ><template #reference>
                <el-button type="primary" plain class="button"
                  >Prosseguir para entrega</el-button
                >
              </template></el-popconfirm
            >
            <el-popconfirm
              @confirm="openOrderReceivePaymentModal()"
              v-if="canShowElement('ReceivePaymentButton')"
              title="
              Tem certeza?
            "
              ><template #reference>
                <el-button type="primary" plain class="button"
                  >Receber Pagamento</el-button
                >
              </template></el-popconfirm
            >
          </el-row>
        </el-row>
      </template>
      <order-receive-payment-modal
        :showModal="showOrderReceivePaymentModal"
        :order="order_"
        @close-modal="showOrderReceivePaymentModal = false"
        @payments-updated="
          updateOrder({
            status: 'completed',
            latitude: coordinates?.latitude,
            longitude: coordinates?.longitude,
          }) |
            $emit('close-modal') |
            $emit('should-update')
        "
      ></order-receive-payment-modal>
      <prepare-deliver-order
        :showModal="showPrepareDeliverOrderModal"
        :order="order_"
        @close-modal="showPrepareDeliverOrderModal = false"
      ></prepare-deliver-order>
    </el-dialog>
  </div>
</template>
<script>
import OrderReceivePaymentModal from "./OrderReceivePaymentModal.vue";
import PrepareDeliverOrder from "./PrepareDeliverOrder.vue";
import OrderModalMainInfo from "../../components/pages/order-modal/main.vue";
import DeliveryInfo from "../../components/pages/order-modal/delivery-info.vue";
import PaymentInfo from "../../components/pages/order-modal/payment-info.vue";
import OrderService from "../../services/orders";
import PartnerService from "../../services/partners";
import InvoiceService from "../../services/invoices";
import BilletService from "../../services/billets";
import { currencyFormatter } from "../../utils/formatters";
import { notifySuccess, notifyError } from "../../utils/notifiers";
import { ElNotification } from "element-plus";
import { messageOnCenter } from "../../utils/notifiers";

export default {
  name: "OrderModal",
  props: ["order", "showModal"],
  components: {
    OrderReceivePaymentModal,
    OrderModalMainInfo,
    PrepareDeliverOrder,
    DeliveryInfo,
    PaymentInfo,
  },
  emits: ["close-modal", "should-update"],
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    OrderIsInProduction() {
      return ["created", "accepted", "production"].includes(this.order_.status);
    },
    Can() {
      return this.$store.state.auth.can;
    },
    OrderClient() {
      return this.client || this.order.client;
    },
    AllPaymentsAreChecked() {
      return this.order_.payments?.every((p) => p.is_checked);
    },
    AllowRecriateBillets() {
      const hasBillets = this.order_?.payments?.some(
        (payment) => payment.type === "billet"
      );

      const alreadyCreatedBillets = [
        "deliver",
        "delivered",
        "completed",
      ].includes(this.order_.status);

      const hasBilletsWithErrors = this.order_?.billets?.some(
        (billet) => !billet.integration_id || billet.status === "error"
      );

      const someBilletWasSucessfull = this.order_?.billets?.some((billet) => ['registered', 'paid'].includes(billet.status));

      return hasBillets && alreadyCreatedBillets && hasBilletsWithErrors && !someBilletWasSucessfull;
    },
  },
  data() {
    return {
      order_: this.order || {},
      isLoading: true,
      isCreatingInvoice: false,
      showOrderReceivePaymentModal: false,
      showPrepareDeliverOrderModal: false,
      currencyFormatter,
      cnpj: {},
      client: null,
    };
  },
  watch: {
    order(v) {
      this.order_ = v;
      if (v?.uid) this.findOrder(v.uid);
      if (v?.client?.uid) {
        this.client = v.client;
        this.findClient(v.client.uid);

        if (v.client.legal) {
          this.fetchClientAPICNPJ(v.client.legal.cnpj);
        }
      }
    },
  },
  methods: {
    async recriateBillets() {
      const queries = this.order.payments.map((p) =>
        BilletService().create({
          firm_bank_account_id: this.bank_billet_id,
          order_payments_id: p.uid,
          partner_id: this.order.client.uid,
          expires_at: new Date(p.expires_at),
          amount: Number(p.amount),
          order_id: this.order.uid,
        })
      );

      await Promise.all(queries);

      notifySuccess('Boletos registrados com sucesso');
    },
    async createOrderInvoice() {
      this.isCreatingInvoice = true;
      const { error } = await InvoiceService().create({
        partner_id: this.order_.client.uid,
        order_id: this.order_.uid,
        firm_id: this.order_.client?.firm?.uid,
      });

      if (error) {
        messageOnCenter(undefined, error.message, "error");
        throw error.message;
      }

      this.isCreatingInvoice = false;
    },
    async fetchClientAPICNPJ(_cnpj) {
      const { cnpj } = await PartnerService().API(_cnpj).get({ api: "CNPJA" });

      if (cnpj) this.cnpj = cnpj;
    },
    getClientPendency() {
      const MIN_SCORE = 50;
      const MAX_DEBT = 0;
      const MAX_OPEN_ORDERS = 2;
      const MIN_CAPITAL = 5000;
      const TOTAL_CREDIT = Number(this.OrderClient?.credit?.total_amount);

      if (this.OrderClient?.score?.number < MIN_SCORE)
        return "O score de crédito do cliente está abaixo do mínimo permitido";
      if (this.OrderClient?.expired_billets?.length > MAX_DEBT)
        return "O cliente possui boletos vencidos";
      if (
        TOTAL_CREDIT &&
        TOTAL_CREDIT - this.sumTotalDebts(this.OrderClient?.debts) <
          this.order_.total_amount
      )
        return "O cliente não possui crédito suficiente para novo pedido";
      if (this.OrderClient?.openedOrders?.length > MAX_OPEN_ORDERS)
        return "O cliente já possui outros pedidos em produção";

      if (this.cnpj?.capital && this.cnpj.capital < MIN_CAPITAL)
        return "O capital social do cliente é menor do que o mínimo";

      return null;
    },
    sumTotalDebts(debts) {
      return debts?.reduce((t, d) => t + Number(d.amount), 0) || 0;
    },
    checkIfAllOrderDataIsAvailable() {
      return (
        this.order_.client &&
        this.order_.seller &&
        this.order_.deliver_at &&
        this.order_.payment_method &&
        typeof this.order_.payment_days != "undefined"
      );
    },
    getPreviousStatus() {
      switch (this.order_.next_status) {
        case "ready":
          return "production";
        case "delivery":
          return "ready";
      }
    },
    async deleteOrder() {
      const { order } = await OrderService(this.order.uid).delete();

      if (order) notifySuccess("Pedido removido com sucesso", order.message);

      this.$emit("close-modal");
      this.$emit("should-update");
    },
    checkIfAllProductsAreChecked() {
      return this.order_?.products?.every((p) => p.is_checked);
    },
    async updateOrder(orderData) {
      let success = false;
      if (orderData) {
        const { order, error } = await OrderService(this.order_.uid).update(
          orderData
        );

        if (error)
          notifyError("Ocorreu um erro ao atualizar o vínculo", error.message);
        else {
          this.findOrder(this.order_.uid);
          this.$emit("should-update");
          notifySuccess(order.message);
        }
      }

      return success;
    },
    async findClient(uid) {
      const { partner } = await PartnerService(uid).get();

      if (partner) this.client = partner;
    },
    async finalizeProduction() {
      if (
        this.checkIfAllProductsAreChecked() &&
        this.checkIfAllOrderDataIsAvailable()
      ) {
        const error = this.getClientPendency();
        await this.updateOrder({
          errors: error || "Pedido vindo da produção, sem erros aparentes",
          status: error ? "analisys" : "ready",
          next_status: "ready",
        });
      } else
        notifyError(
          "Não foi possível finalizar a produção deste pedido",
          "Você deve conferir todos os produtos e as informações do pedido antes de finalizar"
        );

      this.$emit("close-modal");
      this.$emit("should-update");
    },
    driverWasSet() {
      return !!this.order_.driver;
    },
    carWasSet() {
      return !!this.order_.car;
    },
    deliveryOrder() {
      if (this.driverWasSet() && this.carWasSet()) {
        return (this.showPrepareDeliverOrderModal = true);
        //this.updateOrder({ status: "delivery" });
        //return this.$emit("close-modal");
      }

      if (this.driverWasSet())
        ElNotification.error({
          title: "Não é possível prosseguir para entrega",
          message: "Você deve selecionar um veículo para realizar esta entrega",
        });
      else
        ElNotification.error({
          title: "Não é possível prosseguir para entrega",
          message:
            "Você deve selecionar um motorista para realizar esta entrega",
        });
    },
    navigateToPrintOrder() {
      this.$router.push(`/pedidos/${this.order.uid}/imprimir`);
    },
    canAny() {
      for (let a in arguments) if (this.Can(arguments[a])) return true;

      return false;
    },
    async findOrder(uid) {
      const { order } = await OrderService(uid).get();

      if (order) this.order_ = order;

      this.isLoading = false;
    },
    canShowElement(element) {
      switch (element) {
        case "FinalizeProductionButton":
          return (
            ["production", "delayed"].includes(this.order_?.status) &&
            this.canAny("FinalizeProductionOrder", "SuperOrder")
          );
        case "CancelOrderButton":
          return (
            ["created", "analisys", "production", "ready", "delayed"].includes(
              this.order_?.status
            ) && this.canAny("SuperOrder", "CancelOrder")
          );
        case "RemoveOrderButton":
          return (
            [
              "canceled",
              "accepted",
              "paid",
              "delivery",
              "delivered",
              "completed",
              "finalized",
            ].includes(this.order_?.status) && this.canAny("SuperOrder")
          );
        case "BackOrderButton":
          return (
            ["analisys"].includes(this.order_?.status) &&
            this.canAny("UpdateOrder", "SuperOrder")
          );
        case "AcceptOrderButton":
          return (
            this.order_?.status === "analisys" &&
            this.canAny("AcceptOrder", "SuperOrder")
          );
        case "SendToProductionButton":
        case "DeliverOrderButton":
          return (
            this.order_?.status === "ready" &&
            this.canAny("CheckOrder", "SuperOrder")
          );
        case "ReceivePaymentButton":
          return this.order_?.status === "delivered";
        case "SuperOrder":
          return this.canAny("SuperOrder");
        default:
          return true;
      }
    },
    openOrderReceivePaymentModal() {
      this.showOrderReceivePaymentModal = true;
    },
    openPrepareDeliverOrderModal() {
      this.showPrepareDeliverOrderModal = true;
    },
  },
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}

.has-error input {
  border-color: red !important;
}

.text-grey {
  color: grey;
}

.mb-0 {
  margin-bottom: 0px !important;
}
</style>
