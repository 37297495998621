import { uuid } from "vue-uuid";

export function generateDotStringFromCost(cost, startFromLevel = 0) {
	const levels = [cost.category, cost.subcategory, cost.name, cost.entity];
	let treeString = "";

	for (let i = startFromLevel; i < 4; i++) {
		if (levels[i])
			treeString += `${i != startFromLevel ? "." : ""}${levels[i]}`;
	}

	return treeString;
}

export function convertKeysToLabels(o, root = '') {
	const _ = [];
	Object.entries(o).map((e) => {
		_.push({
			label: e[0],
			dotString: `${root ? root + '.' : ''}${e[0]}`,
			id: uuid.v4(),
			hasChildren: !!Object.keys(e[1])?.length,
			children: Object.keys(e[1])?.length ? convertKeysToLabels(e[1], `${root ? root + '.' : ''}${e[0]}`) : [],
		});
	});

	return _;
}

export function getProductMountedName(mounted) {
	return `${mounted.product.name} ${mounted.cutting || ''} ${mounted.composition || ''} ${mounted.storage || ''}`
}

export function generateDotStringFromNode(node) {
	let parent = node.parent;
	let _ = node.data.label;
	while (parent) {
		if ("label" in parent?.data) _ = `${parent?.data?.label}.${_}`;
		parent = parent.parent;
	}
	return _;
}

export function generateObjectFromDotString(o, dotString) {
	let splits = dotString.split(".");
	let mapper = o;
	splits.forEach((key) => {
		if (key in mapper) mapper = mapper[key];
		else {
			mapper[key] = {};
			mapper = mapper[key];
		}
	});
	return o;
}

export const hasOnlyDigits = (s) => /^\d+$/.test(s);

export const isUuid = (uid) =>
	`${uid}`.match(
		/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
	) != null;