<template>
  <el-dialog
    width="90%"
    title="Cadastrar valores a receber"
    append-to-body
    v-model="shouldShow"
  >
    <el-row type="flex" justify="center">
      <h4 class="mb-0">Total do Pedido</h4>
    </el-row>
    <el-row type="flex" justify="center">
      <h2 class="mb-0">
        {{ currencyFormatter.format(Number(order.total_amount) || 0) }}
      </h2>
    </el-row>
    <el-row type="flex" justify="center">
      <h4 class="mb-0">Valor a receber já cadastrado</h4>
    </el-row>
    <el-row type="flex" justify="center">
      <h2 class="mb-0 text-green">
        {{ currencyFormatter.format(Number(calcAmountPaid()) || 0) }}
      </h2>
    </el-row>
    <el-row type="flex" justify="center">
      <h4 class="mb-0">Saldo Restante</h4>
    </el-row>
    <el-row type="flex" justify="center">
      <h2
        :class="{
          'mb-0': true,
          'text-green': Number(calcAmountPaid()) >= Number(order.total_amount),
          'text-red': Number(calcAmountPaid()) < Number(order.total_amount),
        }"
      >
        {{
          currencyFormatter.format(
            (Number(order.total_amount) || 0) - (Number(calcAmountPaid()) || 0)
          )
        }}
      </h2>
    </el-row>
    <el-empty
      v-if="OrderPaymentsAlreadyCreated"
      description="Valores a receber para pedidos em crediário ou boleto já são cadastrados no pré-pedido"
    ></el-empty>
    <div v-else>
      <h4 class="mb-0">Quantidade de Pagamentos:</h4>
      <el-select
        v-model="totalPayments"
        size="large"
        @change="() => (orderPayments = {})"
      >
        <el-option
          v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9]"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <div v-for="(p, i) in Payments" :key="p">
        <h4 class="mb-0">Forma de Pagamento #{{ i + 1 }}:</h4>
        <el-select v-model="orderPayments[i + '.payment_method']" size="medium">
          <el-option
            v-for="(item, index) in PaymentMethod"
            :key="index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>

        <h4 class="mb-0">Valor:</h4>
        <base-input
          v-model="orderPayments[i + '.amount']"
          validationRules="required"
          type="money"
        >
        </base-input>
      </div>
    </div>

    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          :type="thereIsErrorInForm() ? 'danger' : 'primary'"
          size="large"
          class="button"
          :title="thereIsErrorInForm()"
          @click="savePayments"
          :disabled="!!thereIsErrorInForm()"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { currencyFormatter } from "../../utils/formatters";
import OrderService from "../../services/orders";
import { notifyError, notifySuccess } from "../../utils/notifiers";
export default {
  name: "OrderReceivePaymentModal",
  props: ["showModal", "order"],
  components: { BaseInput },
  emits: ["closeModal", "paymentsUpdated"],
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        return this.$emit("close-modal");
      },
    },
    PaymentMethod() {
      return {
        money: "Dinheiro (a vista)",
        credit: "Cartão de Crédito (a vista)",
        debit: "Cartão de Débito (a vista)",
        billet: "Boleto (no prazo)",
        check: "Cheque (a vista)",
        pix: "PIX (a vista)",
      };
    },
    OrderPaymentsAlreadyCreated() {
      return this.OrderIsMoneyOrBillet;
    },
    OrderIsMoneyOrBillet() {
      return this.OrderIsBillet || this.OrderIsMoney;
    },
    OrderIsBillet() {
      return this.order.payment_method === "billet";
    },
    OrderIsMoney() {
      return this.order.payment_method === "cash";
    },
    Payments() {
      return this.OrderPaymentsAlreadyCreated
        ? this.order.payments
        : new Array(this.totalPayments || 1).fill({});
    },
  },
  watch: {
    showModal(v) {
      if (v) {
        if (this.OrderIsMoneyOrBillet) {
          this.savePayments();
        }
      }
    },
  },
  data: () => ({
    productOrder: {},
    products: null,
    totalPayments: null,
    orderPayments: {},
    validationResult: {},
    currencyFormatter,
  }),
  methods: {
    async savePayments() {
      if (!this.OrderPaymentsAlreadyCreated) {
        const orderPayments = this.makeOrderPayments();

        const queries = orderPayments.map((o) =>
          OrderService(this.order.uid).Payments().create(o)
        );

        queries.push(
          OrderService(this.order.uid).update({ status: "completed" })
        );

        await Promise.all(queries)
          .then(() => {
            notifySuccess("Pagamentos do pedido cadastrados com sucesso.");
          })
          .catch(() => {
            notifyError(
              "Ocorreu um erro ao cadastrar os pagamentos do pedido."
            );
          });
      }

      this.$emit("payments-updated");
      this.$emit("close-modal");
    },
    checkifAllAmountWasReceived() {
      const totalAmount = Number(this.order.total_amount);
      const totalPaid = this.calcAmountPaid();
      return totalPaid >= totalAmount;
    },
    calcAmountPaid() {
      return this.makeOrderPayments().reduce(
        (acc, cur) => acc + (Number(cur.amount) || 0),
        0
      );
    },
    thereIsErrorInForm() {
      const { total_amount } = this.order;
      const amount_paid = this.calcAmountPaid();

      if (Math.round(Number(amount_paid)) === Math.round(Number(total_amount)))
        return "";

      if (amount_paid > total_amount)
        return "O valor recebido é maior que o valor da nota.";
      else return "A nota não foi paga completamente";
    },
    makeOrderPayments() {
      if (this.order?.payments?.length) {
        return this.order.payments;
      } else {
        const _ = [];
        for (let o in this.orderPayments) {
          const [i, attr] = o.split(".");

          if (_[i]) _[i][attr] = this.orderPayments[o];
          else
            _[i] = {
              [attr]: this.orderPayments[o],
            };

          _[i]["expires_at"] = new Date();
        }

        return _;
      }
    },
  },
};
</script>
<style scoped>
.text-green {
  color: green;
}
.text-red {
  color: red;
}
</style>
