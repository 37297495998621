import http from "./http";
export default (partnerId) => ({
  async index(filters) {
    return http
      .index("partners", filters)
      .then((json) => ({ partners: json }))
      .catch(async (e) => ({ partners: null, error: await e }));
  },
  async get() {
    return http
      .get("partners", partnerId)
      .then((json) => ({ partner: json }))
      .catch(async (e) => ({ partner: null, error: await e }));
  },
  async create(data) {
    return http
      .post("partners", data)
      .then((json) => ({ partner: json }))
      .catch(async (e) => ({ partner: null, error: await e }));
  },
  async update(data) {
    return http
      .put("partners", partnerId, data)
      .then((json) => ({ partner: json }))
      .catch(async (e) => ({ partner: null, error: await e }));
  },
  async delete() {
    return http
      .delete("partners", partnerId)
      .then((json) => ({ partner: json }))
      .catch(async (e) => ({ partner: null, error: await e }));
  },
  Accounts: (accountId) => ({
    async index(filters) {
      return http
        .index(`partners/${partnerId}/accounts`, filters)
        .then((json) => ({ accounts: json }))
        .catch(async (e) => ({ accounts: null, error: await e }));
    },
    async get() {
      return http
        .get(`partners/${partnerId}/accounts`, accountId)
        .then((json) => ({ account: json }))
        .catch(async (e) => ({ account: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`partners/${partnerId}/accounts`, data)
        .then((json) => ({ account: json }))
        .catch(async (e) => ({ account: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`partners/${partnerId}/accounts`, accountId, data)
        .then((json) => ({ account: json }))
        .catch(async (e) => ({ account: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`partners/${partnerId}/accounts`, accountId)
        .then((json) => ({ account: json }))
        .catch(async (e) => ({ account: null, error: await e }));
    },
  }),
  API: (cnpj) => ({
    async get(filters) {
      return http
        .get("apis", cnpj, filters)
        .then((json) => ({ cnpj: json }))
        .catch(async (e) => ({ cnpj: null, error: await e }));
    },
  })
});
