import http from "./http";
export default (productId) => ({
  async index(filters) {
    return http
      .index("products", filters)
      .then((json) => ({ products: json }))
      .catch(async (e) => ({ products: null, error: await e }));
  },
  async get() {
    return http
      .get("products", productId)
      .then((json) => ({ product: json }))
      .catch(async (e) => ({ product: null, error: await e }));
  },
  async create(data) {
    return http
      .post("products", data)
      .then((json) => ({ product: json }))
      .catch(async (e) => ({ product: null, error: await e }));
  },
  async update(data) {
    return http
      .put("products", productId, data)
      .then((json) => ({ product: json }))
      .catch(async (e) => ({ product: null, error: await e }));
  },
  async delete() {
    return http
      .delete("products", productId)
      .then((json) => ({ product: json }))
      .catch(async (e) => ({ product: null, error: await e }));
  },
});
