<template>
  <div>
    <el-table
      :data="Payments"
      :row-class-name="
        ({ row: r }) => ({ 'is-paid': !!r.paid_at, 'is-expired': r.is_expired })
      "
    >
      <el-table-column width="25px">
        <template #default="{ row: payment }">
          <el-tooltip :disabled="!payment.obs">
            <template #content>
              <span>{{ payment.obs || "" }}</span>
            </template>
            <el-icon
              class="el-icon-info"
              color="#0d84ff"
            ></el-icon> </el-tooltip
        ></template>
      </el-table-column>
      <el-table-column
        label="data cadastro"
        prop="created_at"
        :formatter="(r) => dateFormatter.format(new Date(r.created_at))"
      ></el-table-column>
      <el-table-column
        label="valor"
        :formatter="(r) => currencyFormatter.format(Number(r.amount))"
      ></el-table-column>
      <el-table-column
        label="faturamento"
        prop="payment_method_formatted"
      ></el-table-column>
      <el-table-column
        label="data vencimento"
        :formatter="
          (r) =>
            r.expires_at ? dateFormatter.format(new Date(r.expires_at)) : '-'
        "
      ></el-table-column>
      <el-table-column
        label="data pagamento"
        :formatter="
          (r) => (r.paid_at ? dateFormatter.format(new Date(r.paid_at)) : '-')
        "
      ></el-table-column>
      <el-table-column label="ações" width="350">
        <template #header>
          <el-button
            v-if="canAny('CreateOrderPayment')"
            type="text"
            style="margin-left: auto"
            @click="openOrderPaymentModal(null)"
            >adicionar pagamento</el-button
          >
        </template>
        <template #default="{ row: payment }">
          <el-popconfirm
            @confirm="
              () => updateOrderPayment({ ...payment, paid_at: new Date() })
            "
            :disabled="!!payment.paid_at || payment.payment_method === 'billet'"
            title="Tem certeza?"
            v-if="canAny('UpdateOrderPayment')"
          >
            <template #reference>
              <el-button
                type="primary"
                :disabled="
                  !!payment.paid_at || payment.payment_method === 'billet'
                "
                >Receber pagamento</el-button
              >
            </template>
          </el-popconfirm>

          <el-button
            v-if="canAny('CheckOrderPayment')"
            @click="() => checkOrderPayment(payment)"
            :type="payment.is_checked ? 'success' : 'warning'"
            icon="el-icon-check"
            :disabled="!payment.paid_at"
          >
          </el-button>
          <el-button
            @click="() => openOrderPaymentModal(payment)"
            type="primary"
            icon="el-icon-edit"
            v-if="canAny('UpdateOrderPayment')"
            :disabled="
              payment.is_checked || payment.payment_method === 'billet'
            "
          ></el-button>
          <el-popconfirm
            @confirm="deleteOrderPayment(payment?.uid)"
            v-if="canAny('DeleteOrderPayment')"
            :disabled="!canAny('DeleteOrderPayment')"
            title="
              Remover pagamento?
            "
            ><template #reference>
              <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                :disabled="!canAny('DeleteOrderPayment')"
              >
              </el-button> </template
          ></el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-descriptions border :column="4">
      <el-descriptions-item>
        <template #label> Total do Pedido </template>
        {{ currencyFormatter.format(Number(order.total_amount)) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Total de Pagamentos Cadastrados </template>
        {{ currencyFormatter.format(sumTotalPayments()) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Total de Pagamentos Pagos </template>
        {{ currencyFormatter.format(sumTotalPaidPayments()) }}
      </el-descriptions-item>
      <el-descriptions-item class-name="danger">
        <template #label> Saldo Restante </template>
        {{
          currencyFormatter.format(sumTotalPayments() - sumTotalPaidPayments())
        }}
      </el-descriptions-item>
    </el-descriptions>

    <order-payment-modal
      :showModal="showOrderPaymentModal"
      @should-update="$emit('should-update')"
      @close-modal="showOrderPaymentModal = false"
      :order="order"
      :payment="orderPayment"
    ></order-payment-modal>
  </div>
</template>
<script>
import { dateFormatter, currencyFormatter } from "../../../utils/formatters";
import { notifySuccess, promptOnCenter } from "../../../utils/notifiers";
import { h } from "vue";
import { ElDatePicker } from "element-plus";
import OrderPaymentModal from "../../../views/modals/OrderPaymentModal.vue";
import OrderService from "../../../services/orders";
export default {
  name: "PaymentInfoOrder",
  props: ["order"],
  data() {
    return {
      dateFormatter,
      inputPaidAt: null,
      currencyFormatter,
      orderPayment: null,
      showOrderPaymentModal: false,
    };
  },
  components: {
    OrderPaymentModal,
  },
  computed: {
    Payments() {
      return this.order?.payments || [];
    },
    UncheckedPayments() {
      return this.Payments.filter((p) => !p.is_checked);
    },
    Can() {
      return this.$store.state.auth.can;
    },
  },
  emits: ["should-update"],
  methods: {
    canAny() {
      for (let a in arguments) if (this.Can(arguments[a])) return true;

      return false;
    },
    checkOrderPayment(payment) {
      this.updateOrderPayment({
        ...payment,
        checked_by: this.$store.state.user?.uid,
      });

      if (this.UncheckedPayments?.length === 1)
        this.updateOrder({
          status: "finalized",
        });
    },
    createDateTimeVNode() {
      return h(ElDatePicker, {
        key: this.inputPaidAt,
        placeholder: "Selecione a data",
        "default-value": new Date(),
        modelValue: this.inputPaidAt,
        "onUpdate:modelValue": (r) => {
          this.inputPaidAt = r;
        },
        disabledDate: (date) => date > new Date(),
        format: "DD/MM/YYYY",
      });
    },
    sumTotalPayments() {
      return this.Payments?.reduce((t, p) => t + Number(p.amount), 0);
    },
    sumTotalPaidPayments() {
      return this.Payments?.reduce(
        (t, p) => (p.paid_at ? t + Number(p.amount) : t),
        0
      );
    },
    openOrderPaymentModal(payment) {
      this.orderPayment = payment;
      this.showOrderPaymentModal = true;
    },
    promptPaidAt() {
      return new Promise((resolve) => {
        promptOnCenter("Informe data", "Informar pagamento", {
          showInput: true,
          inputType: "date",
          inputValue: new Date(),
          confirmButtonText: "Pagar",
          cancelButtonText: "Cancelar",
        }).then((response) => {
          if (response.action === "confirm") resolve(response);
          else resolve({ action: "cancel" });
        });
      });
    },
    async processPayment({ uid }) {
      const { action, value: paid_at } = await this.promptPaidAt();

      if (action === "confirm") this.updateOrderPayment({ paid_at, uid });
    },
    async updateOrderPayment({ paid_at, uid, checked_by }) {
      const { payment } = await OrderService(this.order.uid)
        .Payments(uid)
        .update({ paid_at, checked_by });

      if (payment) {
        this.$emit("should-update");
        notifySuccess("Pagamento atualizado com sucesso");
      }
    },
    async updateOrder(orderData) {
      const { order } = await OrderService(this.order.uid).update(orderData);

      if (order) {
        this.$emit("should-update");
        notifySuccess("Pedido atualizado com sucesso");
      }
    },
    async deleteOrderPayment(uid) {
      const { payment } = await OrderService(this.order.uid)
        .Payments(uid)
        .delete();

      if (payment) {
        this.$emit("should-update");
        notifySuccess("Pagamento atualizado com sucesso");
      }
    },
  },
};
</script>
<style>
.el-table .is-paid {
  background-color: #dcedc8 !important;
}
.el-table .is-expired {
  background-color: #fff9c4 !important;
}
</style>
