<template>
  <div>
    <el-descriptions class="margin-top" :column="2" border>
      <el-descriptions-item>
        <template #label> Cliente </template>
        <el-select
          filterable
          remote
          :disabled="!canEditOrder()"
          :remote-method="searchClient"
          :loading="!clients"
          @change="(v) => updateOrder({ client_id: v?.uid })"
          loading-text="Buscando clientes..."
          placeholder="Busque pelo nome, CPF ou CNPJ"
          v-model="order_.client"
          value-key="uid"
          size="medium"
        >
          <el-option
            v-for="item in Clients"
            :key="item.uid"
            :disabled="!item.is_active"
            :label="item.legal?.social_name || item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Vendedor:</template>
        <el-select
          remote
          :disabled="!canEditOrder()"
          @visible-change="(visible) => visible && fetchSellers()"
          :loading="!sellers"
          @change="(v) => updateOrder({ seller_id: v?.uid })"
          loading-text="Buscando vendedores..."
          v-model="order_.seller"
          value-key="uid"
          size="medium"
        >
          <el-option
            v-for="item in Sellers"
            :key="item.uid"
            :disabled="!item.is_active"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Data de entrega </template>
        <el-date-picker
          :disabled="!canEditOrder()"
          v-model="order_.deliver_at"
          placeholder="Data para entrega:"
          @change="(v) => updateOrder({ deliver_at: v })"
          class="mt-1"
          type="datetime"
          size="medium"
          format="DD/MM/YYYY HH:mm:ss"
        >
        </el-date-picker>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Vencimento(s) </template>
        <span v-for="payment in OrderPayments" :key="payment">
          {{
            payment?.expires_at
              ? dateFormatter.format(new Date(payment.expires_at))
              : ""
          }}{{ " " }}
        </span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Faturamento </template>
        <el-select
          :disabled="!canEditOrder()"
          v-model="order_.payment_method"
          size="medium"
          @change="(v) => updateOrder({ payment_method: v })"
        >
          <el-option
            v-for="(item, id) in $store.state.constants.payment_methods"
            :key="item"
            :label="item"
            :value="id"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Romaneio </template>
        <base-input
          :disabled="!canEditOrder()"
          v-model="order_.invoice_id"
          @change="(v) => updateOrder({ invoice_id: v })"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item v-if="!OrderIsInProduction">
        <template #label>Veículo:</template>
        <el-select
          v-model="order_.car_id"
          remote
          @change="(v) => updateOrder({ car_id: v })"
          @visible-change="(v) => v && fetchCars()"
          :loading="!cars"
        >
          <el-option
            v-for="car in cars"
            :key="car.uid"
            :value="car.uid"
            :label="car.name"
          ></el-option> </el-select
      ></el-descriptions-item>
      <el-descriptions-item v-if="!OrderIsInProduction">
        <template #label>Motorista:</template>
        <el-select
          v-model="order_.driver_id"
          remote
          @change="(v) => updateOrder({ driver_id: v })"
          @visible-change="(v) => v && fetchDrivers()"
          :loading="!drivers"
        >
          <el-option
            v-for="car in drivers"
            :key="car.uid"
            :value="car.uid"
            :label="car.employee?.name"
          ></el-option> </el-select
      ></el-descriptions-item>
      <el-descriptions-item v-if="!OrderIsInProduction">
        <template #label>Nota Fiscal:</template>
        <el-select
          v-if="canAny('SuperOrder', 'CreateInvoice')"
          v-model="order_.invoice_config"
          remote
          value-key="value"
          @change="(v) => updateOrder({ invoice_config: v })"
        >
          <el-option
            v-for="config in InvoiceConfigTerms"
            :key="config.value"
            :value="config.value"
            :label="config.label"
          ></el-option> </el-select
      ></el-descriptions-item>
      <el-descriptions-item v-if="!OrderIsInProduction">
        <template #label>Estado do Pedido:</template>
        <el-select
          v-if="canAny('SuperOrder')"
          v-model="order_.status"
          remote
          value-key="value"
          @change="(v) => updateOrder({ status: v })"
        >
          <el-option
            v-for="(label, value) in OrderStatus"
            :key="value"
            :value="value"
            :label="label"
          ></el-option> </el-select
      ></el-descriptions-item>
    </el-descriptions>

    <el-row type="flex" justify="space-between" class="mt-1">
      <h4 class="mb-0">Produtos</h4>
      <el-button
        :disabled="!canEditOrder()"
        type="primary"
        plain
        @click="openAddProductOrderModal"
        >Adicionar produto</el-button
      >
    </el-row>

    <el-table
      :data="OrderProducts"
      empty-text="Nenhum produto foi cadastrado na ordem"
      max-height="400px"
      :cell-style="{ 'padding-left': '4px', 'padding-right': '4px' }"
    >
      <el-table-column
        label="produto"
        prop="product.name"
        :formatter="(r) => printProductMountedName(r?.mounted)"
      ></el-table-column>
      <el-table-column label="qtd" width="200px">
        <template #default="data">
          <base-input
            v-model="data.row.quantity"
            :disabled="!canEditProducts()"
            type="money"
            v-on:keyup.enter="
              updateProductOrder({
                uid: data.row.uid,
                quantity: data.row.quantity,
                checked_by: null,
              })
            "
            :minimumFractionDigits="3"
          >
            <template v-if="data.row.un_quantity" #append>
              {{ data.row.un_quantity }}</template
            >
          </base-input>
        </template>
      </el-table-column>
      <el-table-column label="peso" width="150px">
        <template #default="data">
          <base-input
            v-model="data.row.weight"
            :disabled="!canEditProducts()"
            v-on:keyup.enter="
              updateProductOrder({
                uid: data.row.uid,
                weight: data.row.weight,
                checked_by: null,
              })
            "
            type="money"
            :minimumFractionDigits="3"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="preço por kg" width="400px">
        <template #default="data">
          <base-input
            v-model="data.row.amount"
            :disabled="!CanEditProductPrice"
            v-on:keyup.enter="
              updateProductOrder({
                uid: data.row.uid,
                amount: data.row.amount,
                checked_by: null,
              })
            "
            type="money"
          >
            <template #append v-if="data.row?.mounted?.price">
              Ref:
              {{
                currencyFormatter.format(
                  Number(data?.row?.mounted?.price?.female_std)
                )
              }}
            </template>
            <template #prefix v-if="data.row.amount && data.row.mounted?.price">
              <el-icon
                class="el-icon-arrow-down"
                color="red"
                style="line-height: 36px"
                v-if="
                  Number(data.row.amount) <
                  Number(data.row.mounted?.price?.female_std)
                "
              ></el-icon>
              <el-icon
                class="el-icon-arrow-up"
                color="green"
                style="line-height: 36px"
                v-else
              ></el-icon>
            </template>
          </base-input>
        </template>
      </el-table-column>
      <el-table-column
        label="total do item"
        :formatter="
          (r) => currencyFormatter.format(Number(r.total_amount) || 0)
        "
      ></el-table-column>
      <el-table-column label="observação" prop="obs">
        <template #default="s">
          {{ s.row.obs || "Nenhuma observação" }}</template
        >
      </el-table-column>
      <el-table-column label="ações" width="150px">
        <template #default="{ row: productOrder }">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                :title="getCheckedByName(productOrder)"
                :disabled="!canEditProducts()"
                :type="productOrder.is_checked ? 'success' : 'warning'"
                @click="
                  () =>
                    checkProduct({
                      uid: productOrder.uid,
                      weight: productOrder.weight,
                      amount: productOrder.amount,
                      is_checked: productOrder.is_checked,
                    })
                "
              >
                <i class="el-icon-finished"></i
              ></el-button>
              <el-popconfirm
                title="Remover produto do pedido?"
                :disabled="!canEditProducts()"
                @confirm="removeProductFromOrder(productOrder?.uid)"
              >
                <template #reference>
                  <el-button
                    onlyIcon
                    type="danger"
                    :disabled="!canEditProducts()"
                  >
                    <i class="el-icon-delete"></i
                  ></el-button>
                </template>
              </el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-divider />

    <el-row
      type="flex"
      justify="space-between"
      :gutter="16"
      style="margin-top: 24px"
    >
      <el-col :md="12" :xs="24">
        <el-row justify="center">
          <h4 class="mb-0">Observações</h4>
        </el-row>

        <el-scrollbar max-height="300px">
          <el-table
            :data="OrderObservations"
            v-loading="!orderObs"
            empty-text="Nenhuma observação foi cadastrada"
          >
            <el-table-column
              prop="created_at"
              width="100px"
              label="data"
              :formatter="(r) => dateFormatter.format(new Date(r.created_at))"
            ></el-table-column>
            <el-table-column prop="user.name" label="usuário" width="200px">
              <template #default="data">
                <div class="cell">
                  {{ data?.row?.user?.name || "" }}
                </div>
                <div class="text-grey">
                  {{ data?.row?.ip }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="description" label="observação">
            </el-table-column>
            <el-table-column width="120px">
              <template #header>
                <el-button
                  type="text"
                  style="margin-left: auto"
                  @click="openAddOrderObs"
                  :disabled="!canAny('CreateOrderObs')"
                  icon="el-icon-edit"
                  >adicionar</el-button
                >
              </template>
              <template #default="{ row: orderObs }">
                <el-checkbox-button
                  v-model="orderObs.emit_in_invoice"
                  @change="
                    () =>
                      updateObs(orderObs.uid, {
                        emit_in_invoice: orderObs.emit_in_invoice,
                      })
                  "
                  label="NF"
                ></el-checkbox-button>
                <el-popconfirm
                  @confirm="deleteObs(orderObs?.uid)"
                  style="margin-left: auto"
                  :disabled="!canAny('DeleteOrderObs')"
                  title="
              Remover observação?
            "
                  ><template #reference>
                    <el-button
                      type="danger"
                      plain
                      :disabled="!canAny('DeleteOrderObs')"
                      icon="el-icon-delete"
                    >
                    </el-button></template
                ></el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </el-col>
      <el-col :md="12" :xs="24">
        <el-row type="flex" justify="center">
          <h4 class="mb-0">Movimentações</h4>
        </el-row>
        <el-scrollbar max-height="300px">
          <el-table
            :data="OrderLogs"
            v-loading="!orderLogs"
            empty-text="Nenhuma movimentação ocorreu neste pedido"
          >
            <el-table-column prop="created_at" width="110px" label="data">
              <template #default="{ row: orderLog }">
                <div class="cell">
                  {{ dateFormatter.format(new Date(orderLog.created_at)) }}
                </div>
                <div class="text-grey">
                  {{ timeFormatter.format(new Date(orderLog.created_at)) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="user.name" label="usuário" width="200px">
              <template #default="data">
                <div class="cell">
                  {{ data?.row?.user?.name || "" }}
                </div>
                <div class="text-grey">
                  {{ data?.row?.ip }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="description"
              label="movimentação"
            ></el-table-column>
          </el-table>
        </el-scrollbar>
      </el-col>
    </el-row>
    <el-divider />

    <el-row type="flex" justify="end">
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template #label>Limite de Crédito</template>
          {{
            OrderClientCredit
              ? currencyFormatter.format(OrderClientCredit)
              : "Ilimitado"
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Total do Pedido:</template>
          {{ currencyFormatter.format(Number(order_?.total_amount) || 0) }}
        </el-descriptions-item>
      </el-descriptions>
    </el-row>
    <add-product-order-modal
      :showModal="showAddProductOrderModal"
      @on-add-product="addProductToOrder"
      @close-modal="showAddProductOrderModal = false"
    ></add-product-order-modal>
  </div>
</template>
<script>
import { ElNotification, ElMessageBox } from "element-plus";
import BaseInput from "../../BaseInput.vue";
import SellerService from "../../../services/sellers";
import OrderService from "../../../services/orders";
import CarService from "../../../services/cars";
import DriverService from "../../../services/drivers";
import AddProductOrderModal from "../../../views/modals/AddProductOrder.vue";
import {
  currencyFormatter,
  dateFormatter,
  timeFormatter,
} from "../../../utils/formatters";
export default {
  name: "OrderModalMainInfo",
  props: ["order", "client"],
  components: {
    BaseInput,
    AddProductOrderModal,
  },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      order_: this.order || {},
      clients: null,
      showAddProductOrderModal: false,
      sellers: null,
      obs: "",
      orderObs: null,
      cars: null,
      drivers: null,
      orderLogs: null,
      isLoadingSearch: true,
      isLoading: false,
      dateFormatter,
      currencyFormatter,
      timeFormatter,
    };
  },
  watch: {
    order(v) {
      this.order_ = v;
    },
    order_(v) {
      if (v?.seller) {
        this.order_.seller_id = v.seller.uid;
        this.sellers = [v.seller];
      }

      if (v?.car) {
        this.order_.car_id = v.car.uid;
        this.cars = [v.car];
      }

      if (v.client) {
        this.clients = [v.client];
      }

      if (v?.driver) {
        this.order_.driver_id = v.driver.uid;
        this.drivers = [v.driver];
      }

      this.fetchOrderObs();
      this.fetchOrderLogs();
    },
  },
  computed: {
    OrderStatus() {
      return {
        created: "criado",
        canceled: "estornado",
        analisys: "em análise",
        accepted: "aceito",
        paid: "pago",
        production: "em produção",
        ready: "conferência",
        delivery: "em entrega",
        delivered: "entregue",
        completed: "aguardando pagamento",
        finalized: "finalizado",
        delayed: "em atraso",
      };
    },
    InvoiceConfigTerms() {
      return [
        {
          value: 0,
          label: "Sem nota",
        },
        {
          value: 0.5,
          label: "Meia nota",
        },
        {
          value: 1,
          label: "Nota inteira",
        },
      ];
    },
    OrderPayments() {
      return this.order_?.payments || [];
    },
    OrderClient() {
      return this.client;
    },
    OrderClientCredit() {
      const total_credit = Number(this.OrderClient?.credit?.total_amount);

      if (!total_credit) return total_credit;

      const inuse_credit_orders = this.sumOrderTotalAmounts(
        this.OrderClient?.openedOrders
      );
      const inuse_credit_debts = this.sumDebtsAmount(this.OrderClient?.debts);
      return total_credit - inuse_credit_orders - inuse_credit_debts;
    },
    OrderObservations() {
      return this.orderObs || [];
    },
    OrderLogs() {
      return this.orderLogs || [];
    },
    Clients() {
      return this.clients || [];
    },
    Sellers() {
      return this.sellers || [];
    },
    OrderProducts() {
      return this.order_?.products || [];
    },
    can() {
      return this.$store.state.auth.can;
    },
    OrderIsInProduction() {
      return ["created", "accepted", "production", "delayed"].includes(
        this.order_.status
      );
    },
    PaymentTerms() {
      return this.order_?.payment_method === "cash" ||
        this.order_?.payment_method === "billet"
        ? this.$store.state.constants.payment_days
        : [{ label: "A vista", value: 0 }];
    },
    CanEditProductPrice() {
      return this.canAny("UpdateProductOrderPrice");
    },
    ClientPaymentTerms() {
      return this?.order_?.client?.payment_days?.map((day) => ({
        value: day,
        label: day === "0" ? "A vista" : `${day} dias`,
      }));
    },
  },
  methods: {
    sumDebtsAmount(debts) {
      return debts?.reduce((t, d) => (t += Number(d.amount)), 0) || 0;
    },
    sumOrderTotalAmounts(orders) {
      return orders?.reduce((t, e) => t + Number(e.total_amount), 0) || 0;
    },
    getCheckedByName(productOrder) {
      if (productOrder.checked)
        return `Conferido por: ${
          productOrder?.checked?.name?.split(" ")?.[0] || "desconhecido"
        }`;

      return "";
    },
    getPreviousStatus() {
      switch (this.order_.next_status) {
        case "ready":
          return "production";
        case "delivery":
          return "ready";
      }
    },
    async fetchDrivers() {
      const { drivers } = await DriverService().index({ can_deliver: true });

      if (drivers) this.drivers = drivers;
    },
    async fetchCars() {
      const { cars } = await CarService().index({ can_deliver: true });

      if (cars) this.cars = cars;
    },
    openAddOrderObs() {
      const addObsToOrder = this.addObsToOrder;

      ElMessageBox.prompt("Insira a descrição aqui", "Adicionar observação", {
        confirmButtonText: "Salvar",
        cancelButtonText: "Cancelar",
      })
        .then(({ value }) => {
          addObsToOrder(value);
        })
        .catch(() => {});
    },
    async deleteObs(obsId) {
      if (obsId) {
        const { observation, error } = await OrderService(this.order_?.uid)
          .Observations(obsId)
          .delete();

        if (observation) {
          ElNotification.success({
            title: "Observação removida com sucesso",
          });
          this.fetchOrderObs();
          this.fetchOrderLogs();
          this.obs = "";
        } else {
          ElNotification.error({
            title: "Ocorreu um erro ao remover a observação",
            message: error.message,
          });
        }
      }
    },
    async updateObs(obsId, data) {
      const { observation, error } = await OrderService(this.order_?.uid)
        .Observations(obsId)
        .update(data);

      if (observation) {
        ElNotification.success({
          title: "Observação atualizada com sucesso",
        });
        this.fetchOrderObs();
        this.fetchOrderLogs();
        this.obs = "";
      } else {
        ElNotification.error({
          title: "Ocorreu um erro ao atualizar a observação",
          message: error.message,
        });
      }
    },
    calcOrderTotalAmount() {
      return this.order_?.products?.reduce(
        (t, p) => (t += Number(p.amount) * Number(p.weight) || 0),
        0
      );
    },
    async addObsToOrder(obs) {
      const { observation, error } = await OrderService(this.order_?.uid)
        .Observations()
        .create({
          description: obs,
        });

      if (observation) {
        ElNotification.success({
          title: "Observação cadastrada com sucesso",
        });
        this.fetchOrderObs();
        this.fetchOrderLogs();
        this.obs = "";
      } else {
        ElNotification.error({
          title: "Ocorreu um erro ao cadastrar a observação",
          message: error.message,
        });
      }
    },
    openAddProductOrderModal() {
      this.showAddProductOrderModal = true;
    },
    async addProductToOrder(productOrder) {
      const { product } = await OrderService(this.order_.uid)
        .Products()
        .create({
          ...(productOrder.product || {}),
          amount: productOrder.amount,
          composition: productOrder.composition,
          storage: productOrder.storage,
          weight: productOrder.weight,
          cutting: productOrder.cutting,
          quantity: productOrder.quantity,
          un_quantity: productOrder.un_quantity,
          total_amount: productOrder.total_amount,
          obs: productOrder.product_obs,
          qrcode: productOrder.product?.qrcode,
          ncm:
            productOrder.storage === "frozen"
              ? productOrder.product?.ncm_frozen
              : productOrder.product?.ncm_chilled,
          gtim: productOrder.product?.gtim,
          ean: productOrder.product?.ean,
        });

      if (product) this.updateParentOrder();
    },
    canAny() {
      for (let a in arguments) if (this.can(arguments[a])) return true;

      return false;
    },
    canEditProducts() {
      switch (this.order_?.status) {
        case "created":
        case "production":
        case "accepted":
        case "delayed":
        case "analisys":
          return this.can("UpdateProductOrder");
        default:
          return this.can("SuperOrder");
      }
    },
    canEditOrder() {
      switch (this.order_?.status) {
        case "created":
        case "production":
        case "accepted":
        case "analisys":
          return this.can("UpdateOrder");
        default:
          return this.can("SuperOrder");
      }
    },
    printProductMountedName(mounted) {
      return `${mounted?.product?.name} ${mounted?.cutting ?? ""} ${
        mounted?.composition ?? ""
      } ${mounted?.storage ?? ""}`;
    },
    async fetchSellers() {
      const { sellers } = await SellerService().index();

      if (sellers) {
        this.sellers = sellers;
      }
    },
    async fetchOrderLogs() {
      //this.orderLogs = null;

      const { logs } = await OrderService(this.order_.uid).Logs().index();

      this.orderLogs = logs || [];
    },
    async fetchOrderObs() {
      //this.orderObs = null;

      const { observations } = await OrderService(this.order_.uid)
        .Observations()
        .index();

      this.orderObs = observations || [];
    },
    async updateOrder(orderData) {
      let success = false;
      if (orderData) {
        const { order, error } = await OrderService(this.order_.uid).update(
          orderData
        );

        if (error)
          ElNotification.error({
            title: "Ocorreu um erro ao atualizar o vínculo",
            message: error.message,
            position: "bottom-right",
          });
        else {
          this.updateParentOrder();
          this.$emit("should-update");
          ElNotification.success({
            title: order.message,
            position: "bottom-right",
          });
        }
      }

      return success;
    },
    updateParentOrder() {
      this.$emit("should-update");
    },
    orderShoulBeSetAsProduction() {
      return ["created", "accepted", "delayed"].includes(this.order.status);
    },
    async setOrderAsProduction() {
      this.order_.status = "production";

      await this.updateOrder({ status: this.order_.status });

      this.$emit("should-update");
    },
    async checkProduct(product) {
      if (this.orderShoulBeSetAsProduction()) await this.setOrderAsProduction();

      await this.updateProductOrder(
        {
          uid: product.uid,
          checked_by: product.is_checked ? null : this.$store.state.user.uid,
          weight: product.weight,
          amount: product.amount,
        },
        "O produto foi conferido com sucesso",
        "Não foi possível conferir este produto"
      );
    },
    async updateProductOrder(
      product,
      successMessage = "Produto atualizado com sucesso",
      errorMessage = "Não foi possível finalizar a produção deste produto"
    ) {
      if (!product) return;

      const { error } = await OrderService(this.order_?.uid)
        .Products(product.uid)
        .update(product);

      if (!error) {
        ElNotification.success({
          title: successMessage,
        });
        this.updateParentOrder();
      } else
        ElNotification.error({
          title: errorMessage,
          message: (await error).message,
        });

      return !error;
    },
    searchClient(name) {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      const params = { is_client: true, is_active: true };

      if (Number(name)) params["searchCpfCnpj"] = name;
      else params["searchName"] = name;

      url.search = new URLSearchParams(params);
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.clients = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    async removeProductFromOrder(uid) {
      const { error } = await OrderService(this.order_.uid)
        .Products(uid)
        .delete();

      if (error)
        ElNotification.error({
          title: "Não foi possível remover este produto do pedido",
          message: error.message,
        });
      else {
        ElNotification.success({
          title: "Pedido removido com sucesso",
        });
        this.updateParentOrder();
      }
    },
  },
};
</script>
