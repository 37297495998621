import http from "./http";
export default (firmId) => ({
  async index(filters) {
    return http
      .index("firms", filters)
      .then((json) => ({ firms: json }))
      .catch(async (e) => ({ firms: null, error: await e }));
  },
  async get() {
    return http
      .get("firms", firmId)
      .then((json) => ({ firm: json }))
      .catch(async (e) => ({ firm: null, error: await e }));
  },
  async create(data) {
    return http
      .post("firms", data)
      .then((json) => ({ firm: json }))
      .catch(async (e) => ({ firm: null, error: await e }));
  },
  async update(data) {
    return http
      .put("firms", firmId, data)
      .then((json) => ({ firm: json }))
      .catch(async (e) => ({ firm: null, error: await e }));
  },
  async delete() {
    return http
      .delete("firms", firmId)
      .then((json) => ({ firm: json }))
      .catch(async (e) => ({ firm: null, error: await e }));
  },
  BankAccounts: (bankAccountId) => ({
    async index(filters) {
      return http
        .index(`firms/${firmId}/accounts`, filters)
        .then((json) => ({ accounts: json }))
        .catch(async (e) => ({ accounts: null, error: await e }));
    },
    async get() {
      return http
        .get(`firms/${firmId}/accounts`, bankAccountId)
        .then((json) => ({ account: json }))
        .catch(async (e) => ({ account: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`firms/${firmId}/accounts`, data)
        .then((json) => ({ account: json }))
        .catch(async (e) => ({ account: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`firms/${firmId}/accounts`, bankAccountId, data)
        .then((json) => ({ account: json }))
        .catch(async (e) => ({ account: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`firms/${firmId}/accounts`, bankAccountId)
        .then((json) => ({ account: json }))
        .catch(async (e) => ({ account: null, error: await e }));
    },
  }),
});
