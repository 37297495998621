import http from "./http";
export default (sellerId) => ({
  async index(filters) {
    return http
      .index("sellers", filters)
      .then((json) => ({ sellers: json }))
      .catch(async (e) => ({ sellers: null, error: await e }));
  },
  async get() {
    return http
      .get("sellers", sellerId)
      .then((json) => ({ seller: json }))
      .catch(async (e) => ({ seller: null, error: await e }));
  },
  async create(data) {
    return http
      .post("sellers", data)
      .then((json) => ({ seller: json }))
      .catch(async (e) => ({ seller: null, error: await e }));
  },
  async update(data) {
    return http
      .put("sellers", sellerId, data)
      .then((json) => ({ seller: json }))
      .catch(async (e) => ({ seller: null, error: await e }));
  },
  async delete() {
    return http
      .delete("sellers", sellerId)
      .then((json) => ({ seller: json }))
      .catch(async (e) => ({ seller: null, error: await e }));
  },
});
